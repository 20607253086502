/* eslint-disable import/no-unresolved */
import {
  Box,
  GridItem,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
} from "@chakra-ui/react";
import { useContext } from "react";
import { GiAmpleDress } from "react-icons/gi";
import { LuLeafyGreen } from "react-icons/lu";
import { MdOutlineContactSupport } from "react-icons/md";
import { PiHandCoins } from "react-icons/pi";

import { ShopContext } from "@components/legacy/ShopProvider/context";

import * as S from "./styles";

export const ContactDetails = () => {
  const { demonym } = useContext(ShopContext);

  return (
    <SimpleGrid
      mt="1.5rem"
      columns={{ base: 1, md: 2, lg: 4 }}
      gridAutoRows="1fr"
      gap={6}
    >
      <GridItem w="100%" h="100%">
        <LinkBox display="flex" h="100%">
          <S.StyledBox>
            <GiAmpleDress size={32} />
            <Box as="h3" fontWeight={600} fontSize={16} lineHeight="normal">
              <LinkOverlay fontWeight="bold" href="/dresses">
                ENDLESS DRESS HIRE OPTIONS
              </LinkOverlay>
            </Box>
            <Box as="p">
              Explore a vast collection of designer dress rentals from renowned{" "}
              {demonym} and international designers.
            </Box>
          </S.StyledBox>
        </LinkBox>
      </GridItem>
      <GridItem w="100%" h="100%">
        <LinkBox display="flex" h="100%">
          <S.StyledBox>
            <PiHandCoins size={32} />
            <Box as="h3" fontWeight={600} fontSize={16} lineHeight="normal">
              <LinkOverlay fontWeight="bold" href="/dashboard">
                SHARE AND EARN
              </LinkOverlay>
            </Box>
            <Box as="p">
              Earn by sharing and renting your wardrobe, with opt-in insurance
              keeping you protected.
            </Box>
          </S.StyledBox>
        </LinkBox>
      </GridItem>
      <GridItem w="100%" h="100%">
        <LinkBox display="flex" h="100%">
          <S.StyledBox>
            <LuLeafyGreen size={32} />
            <Box as="h3" fontWeight={600} fontSize={16} lineHeight="normal">
              <LinkOverlay
                fontWeight="bold"
                href="/wear-the-dress-share-the-dress"
              >
                CIRCULAR FASHION
              </LinkOverlay>
            </Box>
            <Box as="p">
              Dress hire on the Volte champions sustainability and circular
              fashion.
            </Box>
          </S.StyledBox>
        </LinkBox>
      </GridItem>
      <GridItem w="100%" h="100%">
        <LinkBox display="flex" h="100%">
          <S.StyledBox>
            <MdOutlineContactSupport size={32} />
            <Box as="h3" fontWeight={600} fontSize={16} lineHeight="normal">
              <LinkOverlay fontWeight="bold" href="/contact">
                24/7 SUPPORT
              </LinkOverlay>
            </Box>
            <Box as="p">
              Around-the-Clock support for your dress rental enquiries. Click
              Live Chat or contact us via phone or email.
            </Box>
          </S.StyledBox>
        </LinkBox>
      </GridItem>
    </SimpleGrid>
  );
};
