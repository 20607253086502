import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { DefaultTheme } from "@styles";

export const StyledBox = styled.div`
  ${({ theme }) => {
    const { space } = theme as DefaultTheme;

    return css`
      background-color: white;
      border: 1px solid;
      border-color: #d8d8d8;
      display: flex;
      flex-direction: column;
      gap: 8px;
      height: 100%;
      padding: ${space[8]};
      width: 100%;
    `;
  }}
`;
